<template>
  <div class="box">
    <div class="container">
      <div class="sat">
        <h1 class="sat_left">
          <p>Take ACT Test</p>
        </h1>
      </div>
      <hr />
      <Type :type="testType" v-if="false" />
      <!-- <hr /> -->
      <el-form ref="form" @submit.native.prevent>
        <el-form-item>
          <el-input
            ref="filterInput"
            placeholder="Name"
            v-model="search.search"
            @keyup.enter.native="searchResult"
          >
            <el-button
              el-button
              slot="append"
              icon="el-icon-search"
              @click="searchResult"
            >
              Search
            </el-button>
          </el-input>
        </el-form-item>
      </el-form>
      <el-alert v-if="resultsTotal > -1" type="success" :closable="false">
        <span slot="title">
          Results: <b>{{ resultsTotal }}</b>
        </span>
      </el-alert>
      <div v-if="practices">
        <div class="text-center" style="margin:15px 0">
          <el-pagination
            background
            @current-change="setPage"
            :page-size="pageSize"
            :current-page="page"
            :total="resultsTotal"
            :pager-count="9"
            layout="prev, pager, next"
          >
          </el-pagination>
        </div>
        <el-table
          v-if="practices.length > 0"
          :data="practices"
          style="width: 100%"
          stripe
        >
          <el-table-column label="Type" width="60">
            <template slot-scope="scope">
              <el-tooltip
                v-if="practiceType === 'composePractices'"
                class="item"
                effect="dark"
                content="Linear Test"
                placement="top"
              >
                <el-tag type="success">
                  <i class="fas fa-arrow-right"></i>
                </el-tag>
              </el-tooltip>
              <el-tooltip
                v-if="practiceType === 'adaptivePractices'"
                class="item"
                effect="dark"
                content="Adaptive Test"
                placement="top"
              >
                <el-tag type="warning">
                  <i class="fas fa-code-branch"></i>
                </el-tag>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="Name" min-width="170">
            <template slot-scope="scope">
              <b>
                {{ getCompanyExamTitle(scope.row.name) }}
              </b>
            </template>
          </el-table-column>
          <template v-if="practiceType === 'composePractices'">
            <!-- <el-table-column label="Subjects" width="100">
              <template slot-scope="scope">
                <b class="text-success">
                  {{ scope.row.practices.length }}
                </b>
              </template>
            </el-table-column> -->
            <el-table-column label="Questions" width="100">
              <template slot-scope="scope">
                <b class="text-success">
                  {{ scope.row.question_count }}
                </b>
              </template>
            </el-table-column>
          </template>
          <el-table-column label="Cost" width="150" v-if="false">
            <template slot-scope="scope">
              <template v-if="isPro || isAdmin">
                <b class="originCoin mr-2">
                  {{ scope.row.exam.coin_plans[0].coin }}
                  {{
                    scope.row.exam.coin_plans[0].coin > 1
                      ? $t("sat.Coins")
                      : $t("sat.Coin")
                  }}
                  <span class="removeLine"></span>
                </b>
                <el-tooltip
                  v-if="isAdmin"
                  class="item"
                  effect="dark"
                  content="Admin"
                  placement="top"
                >
                  <b class="text-success">
                    <i class="fas fa-building"></i>
                    Free
                  </b>
                </el-tooltip>
                <el-tooltip
                  v-else-if="isPro"
                  class="item"
                  effect="dark"
                  content="Pro"
                  placement="top"
                >
                  <b class="admin-Pro" style="color: orange;">
                    <i class="fas fa-crown"></i> Free
                  </b>
                </el-tooltip>
              </template>
              <template v-else>
                <div
                  class="text-success"
                  v-if="freeCounts && scope.row.exam.is_trial"
                >
                  <b class="originCoin mr-2">
                    {{ scope.row.exam.coin_plans[0].coin }}
                    {{
                      scope.row.exam.coin_plans[0].coin > 1
                        ? $t("sat.Coins")
                        : $t("sat.Coin")
                    }}
                    <span class="removeLine"></span>
                  </b>
                  <b>Free</b>
                </div>
                <b v-else>
                  {{ scope.row.exam.coin_plans[0].coin }}
                  {{
                    scope.row.exam.coin_plans[0].coin > 1
                      ? $t("sat.Coins")
                      : $t("sat.Coin")
                  }}
                </b>
              </template>
            </template>
          </el-table-column>
          <el-table-column
            width="130"
            label="Actions"
            fixed="right"
            align="center"
          >
            <template slot-scope="scope">
              <el-tooltip
                class="mr-2"
                effect="dark"
                content="Start a test"
                placement="top"
              >
                <!-- <router-link
                  :to="{
                    name: 'ACTTest',
                    params: {
                      practiceType: practiceType,
                      id: scope.row.id
                    }
                  }"
                  style="color: white !important;"
                >
              </router-link> -->
                <el-button
                  style="color: white !important;"
                  type="success"
                  size="mini"
                  @click="startATest(scope.row.id)"
                >
                  <i class="fas fa-play"></i>
                </el-button>
              </el-tooltip>
              <el-tooltip
                class="mr-2"
                effect="dark"
                content="Browse"
                placement="top"
                v-if="
                  (isAdmin || isTeacher) && practiceType === 'composePractices'
                "
              >
                <router-link
                  :to="{
                    name: routerLink,
                    query: { id: scope.row.exam.test_id }
                  }"
                >
                  <el-button type="success" size="mini">
                    <i class="fas fa-eye"></i>
                  </el-button>
                </router-link>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <div v-else>
          <el-empty :description="$t('courses.empty-text')"></el-empty>
        </div>
        <div class="text-center" style="margin:15px 0">
          <el-pagination
            background
            @current-change="setPage"
            :page-size="pageSize"
            :current-page="page"
            :total="resultsTotal"
            :pager-count="9"
            layout="prev, pager, next"
          >
          </el-pagination>
        </div>
        <el-alert
          type="info"
          class="mt-2 mb-4"
          show-icon
          :closable="false"
          style="text-align:left"
        >
          <span>
            Need more practices? Want a particular type of drill practices?
          </span>
          <a @click="goToFeedbackPage" style="cursor: pointer;">
            <b>Let us know </b>
          </a>
          <span>and we'll create them for you!</span>
        </el-alert>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h1 class="page-title">Take ACT Tests Online</h1>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 pl-0 pr-0">
        <div class="card-box pl-1 pr-1 pt-2 pb-2 mb-1">
          <div class="table-responsive pl-3 pr-3 pt-1 pb-3 mt-1">
            <p>Please click the links below to take your ACT test.</p>
            <table
              class="table table-bordered table-striped table-hover text-center"
            >
              <tr class="text-light bg-primary">
                <th>No</th>
                <th>ACT Exam Name</th>
                <th colspan="2">Test Mode</th>
              </tr>
              <tr v-for="i in 83" :key="i">
                <td>{{ i }}</td>
                <td>December 2020 Form D03</td>
                <td class="td-section">
                  <router-link :to="{ name: 'ACTTest' }">
                    English
                  </router-link>
                  <router-link :to="{ name: 'ACTTest' }">
                    Math
                  </router-link>
                  <router-link :to="{ name: 'ACTTest' }">
                    Reading
                  </router-link>
                  <router-link :to="{ name: 'ACTTest' }">
                    Science
                  </router-link>
                </td>
                <td>
                  <router-link :to="{ name: 'ACTTest' }">
                    Composite
                  </router-link>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Type from "@/views/act/components/Type";
// import Practices from "@/views/act/mockData/Practices.json";
import ACTList from "@/views/act/apis/list.js";
import Common from "@/mixins/common.js";
// import Theme from "@/common/theme";
// import { instant } from "@ivy-way/material";
import role from "@/mixins/role.js";

export default {
  metaInfo() {
    return {
      title: "Take ACT Tests - XMocks Academy"
    };
  },

  components: { Type },

  mixins: [Common, role],

  props: [],
  data() {
    return {
      practices: null,
      resultsTotal: 0,
      pageSize: 0,
      search: {
        search: null,
        source: null
      }
    };
  },
  computed: {
    isPro() {
      return false;
    },
    isAdmin() {
      // let isAdmin = this.isRoleAdmin() ? true : false;
      return false;
    },
    isTeacher() {
      // let isTeacher = this.isRoleTeacher()  ? true : false;
      return false;
    },
    practiceType() {
      if (this.testType === "Full Tests") {
        return "composePractices";
      } else if (this.testType === "Adaptive Tests") {
        return "adaptivePractices";
      } else {
        return "composePractices";
      }
    },
    testType() {
      let testType = "Full Tests";
      if (this.$route.query.type) {
        testType = this.$route.query.type;
      }
      return testType;
    },
    searchString() {
      return this.$route.query.search ? this.$route.query.search : null;
    },
    page() {
      let page = 1;
      if (this.$route.query.page === undefined) {
        page = 1;
      } else {
        page = this.$route.query.page;
      }
      return parseInt(page);
    }
  },
  watch: {},

  mounted() {
    this.search.search = this.searchString;
    this.getExams();
  },

  methods: {
    async startATest(examId) {
      const res = await ACTList.getExam(examId);
      console.log(res);
      let userExamId = res.user_exam.id;
      this.$router.push({
        name: "ACTTest",
        params: {
          examId: examId,
          userExamId: userExamId
        }
      });
    },
    async getExams() {
      const res = await ACTList.getExams({
        exam_type_name: "Full Mock",
        test_name: "ACT",
        page: this.page,
        per_page: 20,
        sort_by: null,
        sort_order: null
      });
      this.practices = res.exams.data;
      this.resultsTotal = res.exams.total;
      this.pageSize = res.exams.per_page;
    },
    getQuestionsCount(practices) {
      let count = 0;
      if (practices && practices.length > 0) {
        practices.forEach(item => {
          count += item.exam.exam_questions_count;
        });
      }
      return count;
    },
    setPage(page) {
      this.$router.push({
        name: this.$route.name,
        params: { ...this.$route.params },
        query: { ...this.$route.query, page: page }
      });
    },
    searchResult() {
      this.$router.replace({
        query: {
          ...this.$route.query,
          ...this.search,
          page: 1
        }
      });
    },
    async goToFeedbackPage() {
      window.open(
        "mailto:satmocks@gmail.com?subject=Requesting Practice Tests",
        "_blank"
      );
    }
  }
};
</script>

<style scoped>
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.5.0/font/bootstrap-icons.css");
.act-box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 14px;
}

.td-section a {
  margin-right: 15px;
}
.table th {
  color: white !important;
}
.sat {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;
}
.box .container {
  padding-top: 0;
}

.sat_left {
  margin: 20px 0;
  display: flex;
  color: var(--themeColor);
  text-decoration: none;
}

.sat_left > span {
  font-weight: 700;
  font-size: 20px;
}
.sat_left > p {
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 0px;
}
</style>
<style scoped src="@/views/act/assets/css/act.css"></style>
<style scoped src="@/views/act/assets/css/simplebar.css"></style>
<style scoped src="@/views/act/assets/css/app.min.css"></style>

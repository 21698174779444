import ApiService from "@/common/api.service";

export default {
  getExams(data) {
    return ApiService.query("/exams", data);
  },
  getExam(examId) {
    return ApiService.post(`/exams/${examId}/purchase`);
  }
};
